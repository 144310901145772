import { Box, Flex } from "@radix-ui/themes";
import { Dialog, DialogTrigger, DialogContent } from "@radix-ui/react-dialog";
import { useAlertVisibility } from "components/contexts/AlertVisibilityContext";
import { useStreamerPageProfile } from "components/contexts/StreamerProfileContext";
import { useToken } from "components/contexts/TokenContext";
import { useUserProfile } from "components/contexts/UserProfileContext";
import ExtensionPlaceholder from "components/extensions/ExtensionPlaceholder";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { selectAllExtensionJWT } from "store/slices/Auth";
import { getExtensionUrl } from "utils/getExtensionUrl";
import SendChatButton from "./SendChatButton";
import "./ChatBottomBar.css"; // Ensure this file handles z-index and absolute positioning
import NewUserChatSettings from "components/settings/middlemaincontent/panels/usersettings/chat/NewUserChatSettings";

function BottomBar() {
  const { token } = useToken();
  const { streamerProfile } = useStreamerPageProfile();
  const userStreamerProfile = useSelector(
    (state: RootState) => state.userStreamerProfile
  );
  const { profile } = useUserProfile();
  const isEditMode = useSelector((state: RootState) => state.editMode);
  const { isCollapsed, toggleCollapse } = useAlertVisibility(); // Use the context

  const getExtensionJWT = useSelector(selectAllExtensionJWT);

  const showAlertButton =
    profile?.isStreamer &&
    profile?.chatrpg_id === streamerProfile?.chatrpg_id &&
    userStreamerProfile?.settings?.alertsButton;

  const streamerPointsExtension = streamerProfile.extensions.find(
    ({ type, enabled }) => type === "Points" && enabled
  );

  const jwtToken = streamerPointsExtension
    ? getExtensionJWT(streamerPointsExtension.clientId)
    : null;

  const shouldShowPoints = token && jwtToken && streamerPointsExtension;
  const extensionUrl = getExtensionUrl(streamerPointsExtension);

  return (
    <>
      <Flex justify="between" align="center" pl="1" pr="2" pb="2" pt="2">
        <Flex align="center" style={{ flex: 1 }}>
          {/* {shouldShowPoints ? (
            <Box maxHeight="2rem" pl="1" asChild>
              <iframe
                src={`${extensionUrl}?token=${getExtensionJWT(
                  streamerPointsExtension.clientId
                )}`}
                className="embeddedProject"
                title="Points Extension"
              />
            </Box>
          ) : null} */}

          {isEditMode && <ExtensionPlaceholder className="defaultSize" />}
        </Flex>

        <Flex justify="between" align="center" gap="2">
          {/* Separate Dialog for Alerts Button */}
          {showAlertButton && (
            <Dialog>
              <DialogTrigger asChild>
                <button className="alertsButton" onClick={toggleCollapse}>🔔</button>
              </DialogTrigger>
            </Dialog>
          )}

          {/* Separate Dialog for Settings Button */}
          <Dialog>
            <DialogTrigger asChild>
              <button className="settingsButton">⚙️</button>
            </DialogTrigger>

            <DialogContent className="settingsPanelAboveButton">
              <NewUserChatSettings /> {/* Settings content goes here */}
            </DialogContent>
          </Dialog>

          <SendChatButton />
        </Flex>
      </Flex>
    </>
  );

}

export default BottomBar;
