import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEmotes } from "../../../providers/EmoteProvider"
import { RootState } from "../../../store"
import { setChatMessage } from "../../../store/reducers/inputChatReducer"
import { parseLinks } from "./helpers/parseLinks"
import "./styles/ChatMessage.css"

interface ChatMessageProps {
  message: string
  displayStyle: "regular" | "system"
}

const ChatMessage: React.FC<ChatMessageProps> = React.memo(
  ({ message, displayStyle }) => {
    const dispatch = useDispatch()
    const chatMessage = useSelector<RootState, string>(
      (state) => state.chatInput.chatMessage
    )

    const { parse: parseEmotes } = useEmotes()

    const parsedEmotes = parseEmotes(message, {
      onEmoteClick: (name) => {
        dispatch(setChatMessage(`${chatMessage} ${name}`))
      },
    })

    const processedMessage = parseLinks(parsedEmotes)

    return (
      <div className={`chat-message-wrapper ${displayStyle === "system" ? "system" : ""}`}>
        {processedMessage}
      </div>
    )
  }
)

export default ChatMessage
