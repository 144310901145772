import React from "react"
import "./styles/ChatUsername.css"

interface ChatUsernameProps {
  username: string
  color: string
}

const ChatUsername: React.FC<ChatUsernameProps> = ({ username, color }) => {
  return (
    <div className="chat-username-wrapper" style={{ color }}>
      {username}
      {username ? <div className="chat-username-colon">: </div> : null}
    </div>
  )
}

export default ChatUsername
