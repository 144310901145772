import PLATFORMS from "../../config/platformConfig"
import { useGlobalBadges } from "../../contexts/GlobalBadgeContext"
import { useStreamerPageProfile } from "../../contexts/StreamerProfileContext"
import { useUserProfile } from "components/contexts/UserProfileContext"
import "./styles/ChatBadge.css"

interface ChatBadgeProps {
  platform: string
  badges?: Record<string, any>
}

function ChatBadge({ platform, badges }: ChatBadgeProps) {
  const { streamerProfile } = useStreamerPageProfile()
  const { globalBadges, chatRpgBadges, kickBadges, youtubeBadges } =
    useGlobalBadges()
  const { profile } = useUserProfile()

  const shouldShowPlatformBadge = profile?.settings?.enablePlatformChatBadges ?? true

  const getTwitchBadgeUrl = (badgeVersion: string): string => {
    const badge = streamerProfile?.twitchBadges.find(
      (b) => b.badge_amount === badgeVersion
    )
    return badge?.badge_url || ""
  }

  const getKickBadgeUrl = (subscriberDuration: string): string => {
    const sortedBadges = (streamerProfile?.kickBadges ?? [])
      .filter((b) => parseInt(b.badge_amount) <= parseInt(subscriberDuration))
      .sort((a, b) => parseInt(b.badge_amount) - parseInt(a.badge_amount))

    return sortedBadges.length > 0 ? sortedBadges[0].badge_url : ""
  }

  const getBadgeImageUrl = (badgeType: string, badgeVersion: any): string => {
    if ((platform === "Twitch" || platform === "TwitchChatRPG") && badgeType === "subscriber") {
      return getTwitchBadgeUrl(badgeVersion)
    }

    if (platform === "Youtube" || platform === "YoutubeChatRPG") {
      const youtubeGlobalBadge = youtubeBadges.find(
        (badge) => badge.badgeType === badgeVersion.badgeType
      )
      return youtubeGlobalBadge?.image || ""
    }

    if (platform === "Kick") {
      if (badgeVersion.type === "subscriber") {
        return getKickBadgeUrl(badgeVersion.count.toString())
      }
      const kickGlobalBadge = kickBadges.find(
        (badge) => badge.badgeType === badgeVersion.type
      )
      return kickGlobalBadge?.image || ""
    }

    if (platform !== "ChatRPG") {
      const globalBadge = globalBadges.find((badge) => badge.set_id === badgeType)
      if (globalBadge) return globalBadge.image_url_1x
    }

    const chatRpgBadge = chatRpgBadges.find((badge) => badge.badgeType === badgeType)
    if (chatRpgBadge) return chatRpgBadge.image

    const streamerBadge = streamerProfile?.twitchBadges.find(
      (b) => b.badge_amount === badgeVersion
    )
    return streamerBadge?.badge_url || ""
  }

  const badgeUrl = PLATFORMS[platform]?.badge || ""

  if (!badgeUrl && !badges) return null

  return (
    <span className="chat-badge-wrapper">
      <div>
        {badgeUrl && shouldShowPlatformBadge && (
          <img src={badgeUrl} alt="" className="chat-badge" />
        )}

        {badges &&
          Object.entries(badges).map(([badgeType, badgeVersion]) => {
            const src = getBadgeImageUrl(badgeType, badgeVersion)
            if (!src) return null
            return <img key={badgeType} src={src} alt="" className="chat-badge" />
          })}
      </div>
    </span>
  )
}

export default ChatBadge
