import React, { ForwardedRef, forwardRef } from "react"
import { useChatBox } from "components/contexts/ChatBoxContext"
import { useUserProfile } from "components/contexts/UserProfileContext"
import ChatBadge from "./ChatBadge"
import ChatMessage from "./ChatMessage"
import ChatMessageModel from "./ChatMessageModel"
import ChatUsername from "./ChatUsername"
import "./styles/ChatLine.css"

interface ChatLineProps {
  message: ChatMessageModel
  isEmbedded?: boolean
}

function ChatLine(
  { message, isEmbedded = false }: ChatLineProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { profile } = useUserProfile()
  const { selectUser } = useChatBox()

  const isMentioned = Boolean(
    profile?.chatrpg_username &&
      message.data.message
        .toLowerCase()
        .includes(profile.chatrpg_username.toLowerCase())
  )

  const handleClick = () => {
    const { data } = message

    console.log("HERE IS OUR DATA " + JSON.stringify(data))
    if (data.user) {
      selectUser(data.user, data.twitch_id, message.type, data.chatrpgId)
    }
  }

  const finalUsernameColor = message.data.color || "#ff0000"

  return (
    <div
      className={`chat-line-wrapper ${isMentioned ? "mentioned" : ""}`}
      ref={ref}
      style={{
        background: isEmbedded
          ? "transparent"
          : isMentioned
          ? undefined
          : message.options.background,
      }}
    >
      <ChatBadge platform={message.type} badges={message.data.badges} />

      {message.data.user && (
        <span onClick={handleClick}>
          <ChatUsername username={message.data.user} color={finalUsernameColor} />
        </span>
      )}

      <ChatMessage
        message={message.data.message}
        displayStyle={message.type === "System" ? "system" : "regular"}
      />
    </div>
  )
}

export default forwardRef(ChatLine)
